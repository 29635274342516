

























































import {
  SfButton, SfLoader, SfProductCard, SfSection,
} from '@storefront-ui/vue';

import {
  computed, defineComponent, onMounted, ref,
} from '@nuxtjs/composition-api';
import {
  useImage,
  useProduct,
} from '~/composables';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
/* import useComparelist from '~/modules/comparelist/composables/useComparelist'; */
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { SortEnum } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'NewProducts',
  components: {
    SfProductCard,
    SfSection,
    SfLoader,
    SfButton,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { isAuthenticated } = useUser();
    const {
      getProductList,
      loading,
      getProductPath,
    } = useProduct();
    const { isInWishlist, addOrRemoveItem } = useWishlist();
    /* const { addOrRemoveItemToCompare, isInComparelist } = useComparelist(); */
    const { addItemToCart, isInCart } = useAddToCart();
    const products = ref([]);

    const mappedProducts = computed(() => products.value.map((product) => ({
      ...product,
      isInWishlist: isInWishlist({ product }),
    })));

    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };

    /* const addItemToComparelist = async (product) => {
      await addOrRemoveItemToCompare({ product });
    }; */
    const { getMagentoImage, imageSizes } = useImage();

    onMounted(async () => {
      const newestProducts = await getProductList({
        pageSize: 4,
        currentPage: 1,
        sort: {
          position: SortEnum.Asc,
        },
      });

      if (newestProducts?.items?.length) {
        products.value = newestProducts.items;
      }
    });

    return {
      addItemToCart,
      addItemToWishlist,
      /* addItemToComparelist, */
      isAuthenticated,
      isInCart,
      isInWishlist,
      /* isInComparelist, */
      loading,
      mappedProducts,
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
    };
  },
});
